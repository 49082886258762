import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common_bottom" }
const _hoisted_2 = { class: "btn" }

import { useStore } from 'vuex';
  import { getCurrentInstance } from 'vue'
  import { useRouter } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CommonBottom',
  setup(__props) {

  const store = useStore();
  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;
  const router = useRouter();

  function eventClick(url: string) {
    globalProperties.$API.GetUserInfo().then((res: any) => {
      if(res.data.code == 200) {
        router.push(url)
      }else {
        globalProperties.$message.warning('您还未登录或者登录已失效，请先登录再操作此按钮！')
      }
    })
  }

return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button, {
        type: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/relation')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_svg_icon, { "icon-class": "mail" }),
          _cache[2] || (_cache[2] = _createTextVNode("联系我们"))
        ]),
        _: 1
      }),
      _createVNode(_component_a_divider, { direction: "vertical" }),
      _createVNode(_component_a_button, {
        type: "text",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (eventClick('/userCenter')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_svg_icon, { "icon-class": "idcard" }),
          _cache[3] || (_cache[3] = _createTextVNode("用户中心"))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", null, _toDisplayString(_unref(store).state.commoInfo.copyright), 1),
    _createElementVNode("p", null, "电话：" + _toDisplayString(_unref(store).state.commoInfo.phone), 1),
    _createElementVNode("p", null, [
      _createTextVNode(" 地址：" + _toDisplayString(_unref(store).state.commoInfo.address) + " ", 1),
      _createElementVNode("span", null, "邮编：" + _toDisplayString(_unref(store).state.commoInfo.fax), 1)
    ])
  ]))
}
}

})