import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navigation_bar" }

import { ref, getCurrentInstance, onMounted, computed, nextTick } from 'vue'
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationBar',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const router = useRouter();
  const route = useRoute();
  const store = useStore()

  let tabIndex = computed(() => {
    return [route.meta.nav]
  })


  let list = ref([
    { title: '首页', nav: 'home', link: 'home' },
    { title: '信息公告', link: 'announcement', nav: 'article', children: [] },
    { title: '检索中心', link: 'searchCenter', nav: 'searchCenter' },
    { title: '标准化服务', link: 'standardizedServices', nav: 'standardizedServices', children: [
      { title: '标准时效性查新', link: 'standardTimelinessSearch' },
      { title: '标准项目立项查新', link: 'standardProjectSearch' },
      { title: '地方标准制（修）订', link: 'localStandardFormulation' },
      { title: '标准化试点、示范项目', link: 'standardizationPilotAndProjects' },
      { title: '正版纸质标准申请', link: 'applicationStandard' },
      { title: '满意度调查', link: 'satisfactionSurvey' },
    ] },
    // children为动态数据后台配置
    { title: '重点产业', link: 'keyIndustries', nav: 'keyIndustries', children: [] },
    { title: '标准科普', link: 'standardScience', nav: 'science', children: [] },
    { title: '关于我们', link: 'companyProfile', nav: 'about', children: [
      { title: '单位简介', link: 'companyProfile' },
      { title: '联系我们', link: 'relation' },
    ] },
    { title: '用户中心', link: 'userCenter', need: true },

  ])

  onMounted(() => {
    showPage('HomeArticleType', 1, 0, 'parentId' );
    showPage('HomeArticleType', 5, 1, 'parentId' );
    showPage('keyIndustriesType', 4, '');
  })

  function showPage(api: any, index: any, type?: any, need?: any) {
    globalProperties.$API[api]({
      parentClassification: type 
    }).then((res: any) => {
      if(res.data.code == 200) {
        let listI = [] as any;
        res.data.data.forEach((item: any) => {
          listI.push({
            title: item.classificationName || item.cateName,
            link: `${list.value[index].link}${ item.cateId ? 'List' : '' }/${item.id || item.cateId}`,
            id: item.id || item.cateId,
            parentId: item.parentId,
            parentClassification: item.parentClassification
          })
        })
        list.value[index].children = listI
      }
    })
  }

  function eventClick(item: any) {
    if(item.need) {
      globalProperties.$API.GetUserInfo().then((res: any) => {
        if(res.data.code == 200) {
          router.push(`/${ item.link }`);
        }else {
          globalProperties.$message.warning('您还未登录或者登录已失效，请先登录！');
        }
      })
    }else {
      router.push(`/${ item.link }`);
    } 
    
  }

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_menu, {
      mode: "horizontal",
      "selected-keys": _unref(tabIndex)
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (!item.children)
              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: item.nav,
                  onClick: ($event: any) => (eventClick(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_a_sub_menu, {
                  key: (item as any).nav,
                  selectable: true,
                  onClick: ($event: any) => (_ctx.$router.push(`/${ item.link }`))
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (line) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item, {
                        key: line.link,
                        onClick: ($event: any) => (_ctx.$router.push(`/${ line.link }`))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(line.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["selected-keys"])
  ]))
}
}

})