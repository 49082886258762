import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed_top" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "ml5" }
const _hoisted_4 = { class: "right" }

import { formatDate } from '@/types/utils'
  import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'FixedTop',
  setup(__props) {

  let date = formatDate(new Date());

  const store = useStore()
  const router = useRouter();
  const route = useRoute();

  let loginType = computed(() => {
    return store.state.loginIn
  })

  onMounted(() => {
    window.addEventListener('scroll', scrollClick);
  })

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollClick)
  }) 

  function scrollClick() {
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let top = document.getElementsByClassName('fixed_top')[0];
    if(scrollTop > 0) {
      (top as HTMLDivElement).style.background = '#0956A8'
    }else {
      (top as HTMLDivElement).style.background = ''
    }
  }

  function eventClick() {
    router.push(`/login?redirect=${ route.fullPath }`)
  }


return (_ctx: any,_cache: any) => {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, "您好，欢迎来到" + _toDisplayString(_unref(store).state.commoInfo.title) + "！", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(date)), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_unref(loginType))
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "text",
              onClick: eventClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_svg_icon, { "icon-class": "user" }),
                _cache[2] || (_cache[2] = _createTextVNode("登录"))
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_a_button, {
              key: 1,
              type: "text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/userCenter')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_svg_icon, { "icon-class": "user" }),
                _cache[3] || (_cache[3] = _createTextVNode("个人中心"))
              ]),
              _: 1
            })),
        _createVNode(_component_a_divider, { direction: "vertical" }),
        _createVNode(_component_a_button, {
          type: "text",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/relation')))
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("联系我们")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})