import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common_header" }
const _hoisted_2 = { class: "header_con" }
const _hoisted_3 = { class: "header_inner" }
const _hoisted_4 = ["src"]

import { ref, getCurrentInstance, computed } from 'vue'
import FixedTop from './FixedTop.vue';
import NavigationBar from './NavigationBar.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  ...{
  components: {
    FixedTop,
    NavigationBar
  }
},
  __name: 'CommonHeader',
  setup(__props) {



const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;
const baseUrl = computed(() => { return globalProperties.baseUrl })
const store = useStore()
const router = useRouter()
let searchValue = ref('')

function searchClick() {
  if(!searchValue.value || /^\s*$/.test(searchValue.value)) return globalProperties.$message.error('请输入标准编号或标准名称！')
  router.push(`/searchCenter?keyword=${ searchValue.value }`)
}





return (_ctx: any,_cache: any) => {
  const _component_icon_search = _resolveComponent("icon-search")!
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FixedTop),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: baseUrl.value + _unref(store).state.commoInfo.logo
        }, null, 8, _hoisted_4),
        _createVNode(_component_a_input_search, {
          modelValue: _unref(searchValue),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchValue) ? (searchValue).value = $event : searchValue = $event)),
          style: { width:'470px', height: '48px' },
          placeholder: "请输入标准编号或标准名称",
          "search-button": "",
          onPressEnter: searchClick,
          onSearch: searchClick
        }, {
          "button-icon": _withCtx(() => [
            _createVNode(_component_icon_search)
          ]),
          "button-default": _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("标准搜索")
          ])),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createVNode(NavigationBar)
    ])
  ]))
}
}

})