import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "layout" }
const _hoisted_2 = {
  class: "layout_container",
  style: { 'minHeight': 'calc(100vh - 480px)' }
}

import { useStore } from 'vuex';
import CommonBottom from './components/CommonBottom.vue'
import CommonHeader from './components/CommonHeader.vue'


export default /*@__PURE__*/_defineComponent({
  ...{
  components: {
    CommonBottom,
    CommonHeader
  },
},
  __name: 'Index',
  setup(__props) {



const store = useStore();

// 需要在这里请求一遍全局参数配置接口，并保存到store中


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_wechat_title = _resolveDirective("wechat-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CommonHeader),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.meta.titleName || _ctx.$route.name == 'home')
        ? _withDirectives((_openBlock(), _createBlock(_component_router_view, { key: 0 }, null, 512)), [
            [_directive_wechat_title, _ctx.$route.name != 'home' ? 
      `${ _ctx.$route.meta.titleName } - ${ _unref(store).state.commoInfo.websiteName }` : 
      `${ _unref(store).state.commoInfo.websiteName }`]
          ])
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ]),
    _createVNode(CommonBottom)
  ]))
}
}

})